import '../bootstrap';
import jQuery from 'jquery';

window.jQuery = jQuery;
window.$ = jQuery;

require('popper.js');
require('bootstrap');

import PasswordStrengthChecker from './../utils/PasswordStrengthChecker';
import './modules/show-hide-password';

new PasswordStrengthChecker('#pass-info');

import './pages/business-register';
import './pages/business-login';
import './pages/forget-pass-business';
import './pages/reset-pass-business';

import './pages/user-login';
import './pages/user-register';
import './pages/forget-pass-user';
import './pages/reset-pass-user';
