import jQuery from 'jquery';
import BusinessRegister from '../../utils/business-register';

const $ = jQuery;
require('select2');

const $category = $('#category');
const $country = $('#country');
const $city = $('#city');

($ => {
	new BusinessRegister('#business-register', '/business/register', 'redirect');

	$category.select2({
		placeholder: 'Select Category',
		ajax: {
			url: `${baseURL}/categories/list`,
			dataType: 'json',
			delay: 250,
			processResults: res => ({
				results: res.map(item => ({
					id: item.id,
					text: item.name,
				})),
			}),
			cache: true,
		}
	});
	const countrySelect2 = $country.select2({
		placeholder: 'Select Country',
		ajax: {
			url: `${baseURL}/countries/list`,
			dataType: 'json',
			delay: 250,
			processResults: res => ({
				results: res.map(item => ({
					id: item.id,
					text: item.name,
				})),
			}),
			cache: true,
		}
	});
	
	const citySelect2 = $city.select2({
		placeholder: 'Select City',
	});
	countrySelect2.on('change', function (e) {
		const id = countrySelect2.select2('data')[0].id || 71;
		citySelect2.select2({
			placeholder: 'Select City',
			ajax: {
				url: `${baseURL}/country/${id}/cities/list`,
				dataType: 'json',
				delay: 250,
				processResults: res => ({
					results: res.map(item => ({
						id: item.id,
						text: item.name,
					})),
				}),
				cache: true,
			}
		});
	});
})(jQuery);
