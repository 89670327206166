import $ from 'jquery';
import { Toast } from './swalToast';
import AjaxEntryResponseHandler from '../front/pages/inc/AjaxEntryResponseHandler';

export default class Register {
	constructor(formId, url, modalId = null) {
		this.url = url;
		this.formId = formId;
		this.ajaxResponseHandler = new AjaxEntryResponseHandler(this.formId);
		this.modalId = modalId;

		this.init();
	}

	init() {
		const self = this;
		$(this.formId).on('submit', function(e) {
			e.preventDefault();
			self.register(this);
		});
		this.ajaxResponseHandler.setCustomHandlers([
			{
				key: 'category_id',
				handler: (key, msg) => {
					$(`[name="${key}"]`).parent().append(`<div class="invalid-feedback d-block custom-err-msg">${msg}</div>`);
					$(`.select2-container`).addClass('is-invalid');
				},
				clear: (key) => {
					$(`[name="${key}"]`).siblings('.custom-err-msg').remove();
					$(`.select2-container`).removeClass('is-invalid');
				}
			},
			{
				key: 'country_id',
				handler: (key, msg) => {
					$(`[name="${key}"]`).parent().append(`<div class="invalid-feedback d-block custom-err-msg">${msg}</div>`);
					$(`.select2-container`).addClass('is-invalid');
				},
				clear: (key) => {
					$(`[name="${key}"]`).siblings('.custom-err-msg').remove();
					$(`.select2-container`).removeClass('is-invalid');
				}
			},
			{
				key: 'city_id',
				handler: (key, msg) => {
					$(`[name="${key}"]`).parent().append(`<div class="invalid-feedback d-block custom-err-msg">${msg}</div>`);
					$(`.select2-selection--single`).addClass('is-invalid');
				},
				clear: (key) => {
					$(`[name="${key}"]`).siblings('.custom-err-msg').remove();
					$(`.select2-selection--single`).removeClass('is-invalid');
				}
			},
		]);
	}

	register(form) {
		const formData = new FormData(form);
		this.ajaxResponseHandler.changeBtnState(true);
		axios
			.post(this.url, formData)
			.then(res => {
				if (this.modalId !== null) $(this.modalId).modal('hide');
				this.ajaxResponseHandler.handleResponse(res, 'redirect');
			})
			.catch(res => {
				this.ajaxResponseHandler.handleResponse(res);
			});
	}
}
